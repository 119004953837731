
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiReaderGetDetail,
         apiReaderDetailUpdate,
         apiReaderCreate,
         apiReaderVersionCreate } from '../services/tpsApi/tpsApiReader';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
import { IReaderDetail } from '../services/tpsApi/models/reader';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
import { apiListMenuGetItem } from '../services/tpsApi/tpsApiListMenuItem';
import { apiPaymentRuleGetVersion } from '../services/tpsApi/tpsApiPaymentRule';
import { apiOperationRuleGetVersion } from '../services/tpsApi/tpsApiOperationRule';
//import DatepickerLite from "vue3-datepicker-lite";
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
    Datepicker,
    //DatepickerLite,
    WarningModal
  },
  emits: ["hide-form"]
})


export default class ReaderForm extends Vue {
    $refs!: {
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData: IReaderDetail = {} as any;
    formData: IReaderDetail = {} as any;
    ruleVer = {} as any;
    actionType: string = "";
    readerID: number = -1;
    brandSelectOptions: {} = {};
    readerSNWarningText: string = "";
    readerNumberWarningText: string = "";
    plateNoWarningText: string = "";
    plateNOFirstHalf: string = ""; //plate_no first data
    plateNOSecondHalf: string = ""; //plate_no second data
    operationVerWarning: string = "";
    paymentVerWarning: string = "";
    programVerWarning: string = "";
    firmwareVerWarning: string = "";
    dateWarningText: string = "";
    PAYMENT_VER_OPTIONS: {} = {};
    OPERATION_VER_OPTIONS: {} = {};
    FIRMWARE_VER_OPTIONS: {} = {};
    READER_VER_OPTIONS: {} = {};
    startdate = {
        id: "startdate",
        class: "md-input myDateInput md-datepicker w-10-rem",
        placeholder: "生效日期",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.ruleVer["version_date"] = value;
        }
    }
    permission = undefined;
    handleDate = (modelData) => {
          this.ruleVer["version_date"] = modelData;
    }
    async beforeMount() {
        this.WRITE = this.$store.getters.write;
        this.permission = this.$store.getters.permission;
    }

    async mounted() {
        this.brandSelectOptions = await apiBrandGetListName();
    }

    async initForm(pActionType, pReaderID) {
        this.actionType = pActionType;
        this.readerID = pReaderID;
        this.readerSNWarningText = "";
        this.readerNumberWarningText = "";
        this.plateNoWarningText = "";
        this.formData = {} as any;
        this.ruleVer = {} as any;
        if(pReaderID) {
            this.formData = Object.assign({}, await apiReaderGetDetail({id:pReaderID}));
            this.originalData = Object.assign({}, this.formData);
            if(this.formData["plate_no"]) {
                this.plateNOFirstHalf = this.formData["plate_no"].split("-")[0];
                this.plateNOSecondHalf = this.formData["plate_no"].split("-")[1];
            }
        }
        else {
            this.plateNOFirstHalf = "";
            this.plateNOSecondHalf = "";
            //Set default select first item
            this.formData['brand_id'] = Object.keys(this.brandSelectOptions)[0];
            this.formData["invalid"] = 0;
        }
        this.PAYMENT_VER_OPTIONS = await apiPaymentRuleGetVersion({filter_brand: this.formData['brand_id']});
        this.OPERATION_VER_OPTIONS = await apiOperationRuleGetVersion();
        this.FIRMWARE_VER_OPTIONS = await apiListMenuGetItem({menuName: "卡機", listName: "韌體版本"});
        this.READER_VER_OPTIONS = await apiListMenuGetItem({menuName: "卡機", listName: "軟體版本"});
    }

    datepickerActive() {
        let datepicker = document.getElementById("datepicker")
        if(datepicker) (datepicker as HTMLFormElement).focus(); 
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    async updateDetail(pSave: boolean) {
        if(pSave) {
            !this.formData["reader_sn"]?this.readerSNWarningText = "卡機號碼不能為空白":this.readerSNWarningText ="";
            !this.formData["readerNumber"]?this.readerNumberWarningText = "卡機內碼不能為空白":this.readerNumberWarningText ="";
            if(this.formData["SAM_easycard"] && this.formData["SAM_easycard"].length >16) return;
            if(this.formData["SAM_ipass"] && this.formData["SAM_ipass"].length >16) return;
            if(this.formData["SAM_icash"] && this.formData["SAM_icash"].length >16) return;

            if( this.formData["reader_sn"] && 
                this.formData["reader_sn"].length <= 9 && 
                this.formData["readerNumber"] &&
                this.formData["readerNumber"].length <=8 &&
                this.plateNOFirstHalf.length <= 4 && 
                this.plateNOSecondHalf.length <= 4) {
                
                this.formData["plate_no"] = this.plateNOFirstHalf + "-" +this.plateNOSecondHalf;
                //Set default modified_time & modified_user
                let dt = new Date(); 
                this.formData["modified_time"] = `${
                    (dt.getMonth()+1).toString().padStart(2, '0')}-${
                    dt.getDate().toString().padStart(2, '0')}-${
                    dt.getFullYear().toString().padStart(4, '0')} ${
                    dt.getHours().toString().padStart(2, '0')}:${
                    dt.getMinutes().toString().padStart(2, '0')}:${
                    dt.getSeconds().toString().padStart(2, '0')}`;
                this.formData["modified_user"] = this.$store.getters.currentUser;

                try {
                    if(this.actionType == "update") {
                        let res = await apiReaderDetailUpdate({ id: this.readerID, 
                                                                data: arrangeUpdateData(this.formData,this.originalData) });
                        if(res.msg === ("ok")) this.hideForm(true);
                        else this.hideForm(false);
                    }      
                    else if(this.actionType == "create") {
                        let res = await apiReaderCreate(this.formData);
                        if(res.msg === ("ok")) this.hideForm(true);
                        else this.hideForm(false);
                    }   
                }
                catch(err) {
                    if(err?.data?.msg?.code == "ER_DUP_ENTRY") {
                        this.readerSNWarningText = "卡機號碼已存在";
                    }
                    if(err?.data?.msg == "plate_no is exist") {
                        this.plateNoWarningText = "車號已存在";
                    }
                }
            } 
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }
    async saveVersion(pSave: boolean){
        if(pSave) {
            if(!this.ruleVer.payment_version)  this.paymentVerWarning = "請選擇付款參數版本";

            if(!this.ruleVer.operation_rule_version)  this.operationVerWarning = "請選擇營運參數版本";
            if(!this.ruleVer.firmware_version)  this.firmwareVerWarning = "請選擇營運參數版本";
            if(!this.ruleVer.program_version)  this.programVerWarning = "請選擇營運參數版本";

            if(!this.ruleVer.version_date) this.dateWarningText = "請選擇生效日";

            if(this.ruleVer.payment_version && this.ruleVer.operation_rule_version && this.ruleVer.firmware_version && this.ruleVer.program_version && this.ruleVer.version_date){
                //console.log(this.ruleVer);
                this.ruleVer.version_date = this.ruleVer.version_date.replace(/-/g,'');
                this.ruleVer.reader_number = this.originalData["readerNumber"];
                let res = await apiReaderVersionCreate({ data: this.ruleVer});
                if(res.msg === ("ok")){ this.ruleVer = {} as any;  this.hideForm(true);}
                else this.hideForm(false);
            }
        }
        
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }
}
