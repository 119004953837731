
import { Options, Vue } from 'vue-class-component';
import { apiReaderGetList,
         apiReaderGetListTotalNum } from '../services/tpsApi/tpsApiReader';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import { IReaderList } from '../services/tpsApi/models/reader';
import Alert from '@/components/Alert.vue';
import Table from '@/components/Table.vue';
import ActionMenuSearchBrand from '@/components/ActionMenuSearchBrand.vue';
import ReaderForm from '@/components/ReaderForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    Table,
    ActionMenuSearchBrand,
    ReaderForm,
    Pagination
  },
})
export default class Reader extends Vue {
    $refs!: {
        form: ReaderForm,
        alert: Alert
    }

    TABLE_HEAD: {} = {
        update_time:"卡機最後上線時間",
        reader_sn: "卡機號碼",
        readerNumber: "卡機內碼",
        SAM_easycard: "悠遊卡",
        SAM_ipass: "一卡通",
        SAM_icash: "愛金卡",
        plate_no: "車號",
        brand_id: "所屬品牌",
        modified_user: "最後執行人員",
        modified_time: "最後執行時間",
        invalid: "狀態"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    readerList: IReaderList | Blob = [];
    filter: string | undefined = undefined;
    filter_brand: number | undefined = undefined;
    pageNo: {} = PAGENO;
    
    mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        this.pageTotalNum = await apiReaderGetListTotalNum({filter: this.filter});
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType,pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {filter:this.filter,filter_brand:this.filter_brand});
        this.readerList = await apiReaderGetList(pData);
    } 
    
    async download(pData: { output: string,filter_brand: number }) {
        const res = await apiReaderGetList({output: pData.output, filter: this.filter,filter_brand:this.filter_brand});
        exportFile({ fileName: "卡機資料", data: res });
    }

    doSearch(pData: {filter: string  ,filter_brand: number}) {
        this.filter = pData.filter;
        this.filter_brand = pData.filter_brand;
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }

    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    }
}
